// ENV_URL definido no webpack.config
// const ENV = ENV_URL;
// const ENV = 'prod'; // força produção pra teste de dados tempo real
//const ENV = 'dev';

const getURI = (api) => {

    //    console.log('URL');
    //    console.log (process.env.REACT_APP_AMBIENTE);
    //    console.log(window.location.href);
    //   console.log(window.location.host);

    // se rodar com npm start
    // console.log(process.env.NODE_ENV);
    if (process.env.NODE_ENV == 'development')
        return 'http://localhost:2200';

    //else if (process.env.NODE_ENV == 'production')
    let url = window.location.host;
    let prefix = url.startsWith("localhost") || url.includes(":") ? 'http://' : 'https://';
    console.log(prefix);
    return (prefix + url);

    //if (process.env.REACT_APP_AMBIENTE)
    //   return process.env.REACT_APP_AMBIENTE;
    // else		
    //  return 'http://localhost:2200';
}

const endpointsConfig = {
    login: `${getURI('login')}/api/v1/login`,
    user: `${getURI('user')}/api/v1/user`,
    role: `${getURI('role')}/api/v1/role`,
    room: `${getURI('room')}/api/v1/room`,
    configDB: `${getURI('configDB')}/api/v1/configDB`,
    configJson: `${getURI('configJson')}/api/v1/configJson`,
    publicInfo: `${getURI('publicInfo')}/api/v1/publicInfo`,

    dispenser: `${getURI('dispenser')}/api/v1/dispenser`,
    irrigation: `${getURI('irrigation')}/api/v1/irrigation`,

    histUsers: `${getURI('histUsers')}/api/v1/histUsers`,
    histCommands: `${getURI('histCommands')}/api/v1/histCommands`,
    histSensors: `${getURI('histSensors')}/api/v1/histSensors`,
    histDispensers: `${getURI('histDispensers')}/api/v1/histDispensers`,
    histDispensersComm: `${getURI('histDispensersComm')}/api/v1/histDispensersComm`,
    util: `${getURI('util')}/api/v1/util`,
    dashboard: `${getURI('dashboard')}/api/v1/dashboard`,

    area: `${getURI('area')}/api/v1/area`,
    floor: `${getURI('floor')}/api/v1/floor`,
    hotel: `${getURI('hotel')}/api/v1/hotel`,
    hotelChain: `${getURI('hotelChain')}/api/v1/hotelChain`,

}

export default endpointsConfig;
