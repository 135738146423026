import decode from 'jwt-decode';
import request from 'then-request';
import config from './endpoint.config';

export default class AuthService {


    static login(username, password) {
        return new Promise((resolve, reject) => {

            request('POST', config.login, {
                headers: AuthService.getHeaders(),
                body: JSON.stringify({ username, password, channel: 'WEB' })
            }).then((res) => {

                if (res.statusCode !== 200) {
                    //reject({ error: "Usuário ou senha inválidos" });
                    let msg = res.body;//JSON.stringify(//res.body.message ? res.body.message : "Usuário ou senha inválidos.";
                    reject({ message: msg });
                    return;
                }

                // Fev24 - Antes a resposta vinha o token + user
                // Porem dentro do token tem o user tambem
                // entao eu decodifico o token, pego o user e copio aqui, assim ele nao transmite o user 2x
                let auth = JSON.parse(res.body);
                let decodedTok = decode(auth.token);
                auth.user = decodedTok.user;
                this.saveAuth(auth);
                resolve(auth);
            }).catch(reject)
        });
    }

    static logout() {
        localStorage.removeItem('auth');
        sessionStorage.removeItem('auth');

    }

    static getHeaders() {
        const headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        };
        return headers;
    }

    static getHeadersWithToken() {

        const token = this.getToken();
        const headers = this.getHeaders();
        headers['Authorization'] = 'Bearer ' + token;

        return headers;
    }

    // static autenticaUsuario(menu) {

    //     return (nextState, replace) => {
    //         const naoEstaLogado = !AuthService.loggedIn();
    //         if (naoEstaLogado) {
    //             replace('/login?path=' + encodeURIComponent(nextState.location.pathname));
    //             return;
    //         }

    //         const podeAcessarEsteMenu = AuthService.contemPermissaoParaEsteMenu(menu);
    //         if (!podeAcessarEsteMenu) {
    //             // replace(`/home?erro=${encodeURIComponent('Este usuário não possui permissão para acessar o menu: '  + menu)}`);

    //             replace('/home');
    //             const error = 'Este usuário não possui permissão para acessar o menu: ' + menu;

    //             smallBox({
    //                 title: "Acesso Negado!",
    //                 content: `${error}<br/><br/>`,
    //                 color: "#AA0000",
    //                 timeout: 10000,
    //                 icon: "fa fa-warning"
    //             });
    //         }

    //         return;
    //     }
    // }

    static loggedIn() {
        const token = this.getToken(); // GEtting token from localstorage
        return !!token && !this.isTokenExpired(token); // handwaiving here
    }

    static isTokenExpired(token) {
        try {
            const decoded = decode(token);
            if (decoded.exp < Date.now() / 1000) { // Checking if token is expired. N
                return true;
            } //else {
            //return false;
            // }
        } catch (err) {
            //return false;
        }

        return false;
    }

    static getToken() {

        const auth = this.getAuth();

        if (!auth) {
            return '';
        }
        //console.log('TOKENNNNN');
       // console.log(' ');
        //console.log(auth.token);

        return auth.token;
    }

    static getAuth() {
        let auth = sessionStorage.getItem('auth');
        if (auth === null) {
            auth = localStorage.getItem('auth');

            if (auth !== null) {
                sessionStorage.setItem('auth', auth);
            }
        }

        return JSON.parse(auth);
    }

    static saveAuth(auth) {

        // if (manterConectado) {
        //     localStorage.setItem('user', JSON.stringify(usuario));
        // }

        sessionStorage.setItem('auth', JSON.stringify(auth));
    }

    static getPermissions() {
        const auth = this.getAuth();
        if (!auth) {
            return null;
        }

        return auth.user && auth.user.role ? auth.user.role.permissions : [];
    }

    static checkPermissions(perm) {
        const auth = this.getAuth();
        if (!auth) {
            return null;
        }

     //   console.log('CHECK PERMISSIONS');
     //   console.log(auth.user);

        let permissions = auth.user && auth.user.role ? auth.user.role.permissions : [];
        //console.log('permissions');
        //console.log(permissions);

        // returna true se o usuario atual tem a permission 'admin' ou a mesma permissao passada na funcao
        return ((permissions.indexOf('admin') > -1) || (permissions.indexOf(perm) > -1));
    }

    static async getInitialScreenApp() {
    
        const auth = this.getAuth();
        if (!auth) {
            return null;
        }

        return auth.user && auth.user.role ? auth.user.role.initialScreenApp : '';
    }

    // Used in the website
    static async getInitialScreen() {
    
        const auth = this.getAuth();
        if (!auth) {
            return null;
        }

        return auth.user && auth.user.role ? auth.user.role.initialScreen : '';
    }
    
    static getName() {
        const auth = this.getAuth();
        if (!auth) {
            return null;
        }

        return auth.user ? auth.user.name : '';
    }

    // Usado qdo troca o nome do usuario, e o proprio usuario esta logado (pra atualizar a tela)
    static setName(newName) {
        let auth = this.getAuth();
        if (!auth) {
            return null;
        }

        auth.user.name = newName;
        this.saveAuth(auth);
    }

    static getFotoPath() {
        const auth = this.getAuth();
        if (!auth) {
            return null;
        }

        if (!auth.user || !auth.user.photo || !auth.user.photoURL)
            return '../../assets/img/avatars/default1.jpg';

        let url = auth.user.photoURL ? auth.user.photoURL :
            window.location.host.startsWith("localhost") ?
                'http://localhost:2200/uploads/' + auth.user.photo :
                'https://' + window.location.host + '/uploads/' + auth.user.photo;

        //console.log('photo');
        //console.log(photoURL);
        return url;
    }

    // Usado qdo troca o nome do usuario, e o proprio usuario esta logado (pra atualizar a tela)
    static setFoto(newFoto) {
        let auth = this.getAuth();
        if (!auth) {
            return null;
        }

        auth.user.photo = newFoto;
        this.saveAuth(auth);
    }

    // Usado qdo troca o nome do usuario, e o proprio usuario esta logado (pra atualizar a tela)
    static setFotoURL(newFotoURL) {
        let auth = this.getAuth();
        if (!auth) {
            return null;
        }

        auth.user.photoURL = newFotoURL;
        this.saveAuth(auth);
    }

    static getPhoto() {
        const auth = this.getAuth();
        if (!auth) {
            return null;
        }

        return auth.user ? auth.user.name : '';
    }
    static getPhotoURL() {
        const auth = this.getAuth();
        if (!auth) {
            return null;
        }

        return auth.user.photoURL;
    }

    static async isSuperUsername() {
        let user = await this.getUsername();
        return (user == 'samian');
    }
    
    static getUsername() {
        const auth = this.getAuth();
        if (!auth) {
            return null;
        }

        return auth.user ? auth.user.username : '';
    }

    static getEmail() {
        const auth = this.getAuth();
        if (!auth) {
            return null;
        }

        return auth.user ? auth.user.email : '';
    }

    static getInitialScreen() {
        const auth = this.getAuth();
        if (!auth) {
            return null;
        }

        return auth.user && auth.user.role ? auth.user.role.initialScreen : '';
    }

    static getInitialScreenHandset() {
        const auth = this.getAuth();
        if (!auth) {
            return null;
        }

        return auth.user && auth.user.role ? auth.user.role.initialScreenHandset : '';
    }

    static getUserId() {
        const auth = this.getAuth();
        if (!auth) {
            return null;
        }

        return auth.user ? auth.user._id : 0;
    }

    // static getTipoUsuario() {

    //     const user = this.getUsuario();

    //     if (!user) {
    //         return {}
    //     }

    //     const tipoUsuario = {
    //         idOperadoraTransporte: user.idOperadoraTransporte,
    //         idOrgaoGestor: user.idOrgaoGestor,
    //         idConsorcio: user.idConsorcio
    //     };

    //     return tipoUsuario;
    // }

    // static contemPermissaoParaEsteMenu(permissao) {

    //     if (!permissao) {
    //         return false;
    //     }

    //     const user = this.getUsuario();

    //     if (!user) {
    //         return false;
    //     }

    //     const contemPermissaoParaEsteMenu = user.menusParaAcesso.includes(permissao);
    //     return contemPermissaoParaEsteMenu;
    // }


}