
//import { Label } from 'reactstrap';
import React, { Component } from 'react';
import {
    Label, Row
} from 'reactstrap';
import './SplashScreen.css';
import logo from '../../assets/img/brand/logo_loading.png';

export default class SplashScreen extends Component {

    state = {
    }

    componentDidMount() {

    }

    montaTelaMain = () => {
        return (
            <div className="app flex-row align-items-center splash-screen-main">
                <div>
                    <img src={logo}
                        style={{ width: '80px', heigth: '80px', opacity: '60%' }}
                        alt='Fluxmon'
                    //className="loading-logo" /* por alguma razao o opacity nao funciona no heroku, entao tive q por o style aqui */
                    />
                    <div className="loading-spin-dot">.</div>
                    <div className="loading-spin-dot2">.</div>
                    <div className="loading-spin-dot3">.</div>
                    <div className="loading-spin-dot4">.</div>
                    <div className="loading-spin-dot5">.</div>
                </div>
                <Label style={{ opacity: '60%', fontSize: '2rem' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Bem vindo ao Fluxmon!</Label>

            </div>
        )
    }

    montaTela = () => {
        return (
            <div className="splash-screen">

                {/* <h1>Aguarde um Momento - Carregando página</h1>*/}
                <img src={logo}
                    style={{ width: '50px', heigth: '50px', opacity: '60%' }}
                    alt='Samian'
                //className="loading-logo" /* por alguma razao o opacity nao funciona no heroku, entao tive q por o style aqui */
                />
                <div
                    //className="loading-dot"
                    className="loading-spin-dot"
                >.</div>

                <Label style={{ opacity: '60%' }}>Carregando...</Label>
            </div>
        )
    }

    //////////////////////////////////////////////////////////////////////
    render() {
        if (this.props.main) {
            return (
                <>
                    {this.montaTelaMain()}
                </>
            );
        } else {
            return (
                <>
                    {this.montaTela()}
                </>
            );
        }

    }
}